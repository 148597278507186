body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.maingrid{
  width: calc(100%);
  height: fit-content;
  padding: 20px;
  padding-top: 5px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}


footer{
  width: calc(100%);
  height: 360px;
  background-color: #000;
  padding: 20px;

}


.footercont{
  display: grid;
  grid-template-columns: 25% 18.75% 18.75% 18.75% 18.75%;
  width: 100%;
  height: calc(100% - 40px);

}

.copyrightcont{
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}


.copyright{
  width: fit-content;
  display: flex;
  align-items: center;
  color: #ffffff96;
  font-family: var(--secondaryfont);
  letter-spacing: 0.8px;
}

.termsconditions{
  color: #ffffff96;
  font-family: var(--secondaryfont);
  letter-spacing: 0.8px;
  margin-left: auto;
}
.aboutcont,.shoopinglinks,.informationcont,.followus,.manageordercont{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutmain,.shoopinglinkscont,.informationmain,.followcont,.managecont{
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.brandcont{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  font-family: var(--mainfont);
  color: #fff;
  font-size: 55px;
  letter-spacing: 1px;
}

.brandinsp{
  width: 100%;
  height: 80%;
  display: flex;
  font-family: var(--secondaryfont);
  font-weight: 500;
  font-size: 12px;
  color: #ffffff96;
}

.shoplinkspan,.infospan,.followspan,.managespan{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--secondaryfont);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.shoplink,.infolink,.managelink{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.shoplink a,.infolink a,.managelink a{
  color: #ffffff96;
  font-family: var(--secondaryfont);
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  margin-top: 20px;
}

.shoplink a:hover
{
  color:#BA181B;
}
.infolink a:hover{
  color:#BA181B;

}

.managelink a:hover{
  color:#BA181B;

}
.shoplink a:nth-child(1),.infolink a:nth-child(1),.managelink a:nth-child(1){
  margin-top: 0px;
}

.followsvg{
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.followsvg a{
  text-decoration: none;
  padding: 5px;
  padding-right: 20px;
  color: #ffffff96;
}


@media only screen and ( max-height:745px ) {
  nav{
      height: 60px;
 }

 .logocont{
      width: 60px;
      height: 60px;
 }
 
 .home,.exclusive,.newarrival,.brands{
  font-size: 14px;
 }



 .cart svg{
  width: 20px;
  height: 20px;
 }

 .account svg{
  width: 18px;
  height: 18px;
 }

}

@media only screen and ( max-width:810px )
{
  .search{
      display: none;
  }
  .cart{
      margin-left: auto;
  }

}

@media only  screen and (max-width:700px) {
  .home,.newarrival,.brands,.exclusive{
      display: none;
  }

  nav{
      justify-content: space-between;
  }

  .cart{
      width: 15%;
      margin-left: 0px;

  }

  .account{
      width: 15%;
      display: none;

  }

  .logo{
      width: calc(70% - 60px);

  }

  .more{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      aspect-ratio: 1/1;
  }


  .homelink{
      width: 40%;
  }

  .shopbyexclcusivecont{
      display: block;
  }

  .exclusivespan{
      width: 100%;
      height: fit-content;
  }

  .opencont{
      display: none;
  }

  .shopbyexclcusivecont{
      height: 400px;
      width: calc(100%);
      padding: 0 5px;
      overflow-x: scroll;
  }

  .exclusivecont{
      height: 90px;
  }


  .exclusivespan{
      width: calc(100%);
  }

  .catogerycont{
      width: calc(100%);
      height: 310px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow-x: scroll;
      min-width:calc(100%);

  }

  .catogerycard{
      margin-right: 0;
      aspect-ratio: 3/4;
      height: 300px;
  }

  .filtercont{
      display:none;
  }

  .sortfiltercont{
      display: none;
  }
}


@media only  screen and (max-width:473px) {
  .homelink{
      width: 70%;
  }
}



@media only screen and (max-width:1090px){
  .footercont{
      grid-template-columns: 30% 17.5% 17.5% 17.5% 17.5%;
  }
}


@media only screen and (max-width:960px){
  footer{
      height: 450px;
  }
  .footercont{
      grid-template-columns: 25% 25% 25% 25%;
  }
  .brandcont{
      font-size: 45px;
  }
  .brandinsp p{
      font-size: small;
  }

  .followsvg{
      height: 50%;
  }
}

@media only screen and (max-width:890px){

  .footercont{
      grid-template-columns: 30% 23.3% 23.3% 23.3%;
  }
}


@media only screen and (max-width:735px){
  .brandcont{
      font-size: 35px;
  }

  .footercont{
      grid-template-columns: 30% 35% 35%;
  }

  .shoopinglinkscont{
      width: 60%;
  }
  .informationmain{
      width: 80%;
  }
  footer{
      height: 500px;
  }
  .followsvg{
      height: 30%;
  }
}


@media only screen and (max-width:650px){
  footer{
      display: flex;
      flex-direction: column;
      height: fit-content;
  }
  .footercont{
      display: flex;
      flex-direction: column;   
      height: fit-content;
      gap: 10px;

  }
  .aboutcont{
      height: 150px;
  }
  .shoopinglinks{
      height: 200px;
  }


  .shoopinglinkscont{
      width: 90%;
  }

  .manageordercont{
      height: 200px;
  }

  .informationmain{
      width: 90%;
  }
  .followus{
      height: 80px;
  }
  .followspan{
      height: 40%;
  }
  .followsvg{
      height: 60%;
  }
  .informationcont{
      height: 250px;
  }
}

@media only screen and (max-width:950px) {
  .maingrid{
      grid-template-columns: 33.33% 33.33% 33.33%;
  }
}


@media only screen and (max-width:500px) {
  .maingrid{
      grid-template-columns: 50% 50%;
  }
}

@media only screen and (max-width:450px) {
  .maingrid{
      padding: 20px 5px;
      width: calc(100%);
  }

  .product{
      width: calc(100%);
  }


}


/* prodcu start */

.mainproductcont{
  width: 100%;
  height: fit-content;
  display: flex;
}


.maindiscriptioncont,.mainsizecont{
  width: calc(30% - 20px);
  padding: 0px 10px;
  display: flex;
  flex: auto;
}

.absdiscriptionmain,.abssizemain{
  width: 100%;
  height: calc(100vh - 40px);
  padding: 20px 0;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
}

.mainimgslider{
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 130px;
  align-items: center;

}

.imggg{
  min-width: 97%;
  width: 97%;
  aspect-ratio: 3/4;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eaeaea;
}


.productdetail{
  width: 100%;
  height: 50px;
  margin-top: auto;
  display: flex;

}


.absnamecont{
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: var(--test);
  font-size: 12px;
  font-weight: 600;
}

.abspricecont{
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: var(--test);
  font-size: 10px;
  font-weight: 600;
  color: #0000008b;
}

.discriptiontabel{
  width: 100%;
  height: fit-content;
}

.discriptiontabelhead{
  width: 100%;
  height: 40px;
}

.dhead{
  height: 38px;
  width: 100%;
  display: flex;
}

.discriptionhead,.detailhead,.shippindhead{
  width: 33.33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--test);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

}

.shippinginfo,.detailinfo{
  display: none;
  flex-direction: column;
  font-family: var(--test);
  font-size: 10px;
  padding:10px 5px ;
}

.shippinginfo span{
  padding: 2px 0px;
}

.dbar{
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background-color: #ccc;
}

.bbar{
  width: 33.33%;
  height: 100%;
  background-color: black;
  border-radius: 5px;
  transition: all 0.3s ease;

}

.discriptiontabelinfo{
  width: calc(100% - 10px);
  min-height: 60px;
  height: fit-content;
  padding: 2.5px 5px;
}

.discription{
  font-family: var(--test);
  font-size: 10px;

}

.abssizecont{
  margin-top: auto;
  width: 100%;
  height: fit-content;
  position: relative;
}

.sizehead{
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sizehead span{
  font-size: 12px;
  font-family: var(--test);
  font-weight: 500;
}

.sizechart{
  width: 80px;
  height: 25px;
  background-color: black;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fafafa;
  font-family: var(--test);
  font-size: 12px;
  font-weight: 500;
  margin-left: auto;
}

.sizecont{
  padding: 10px 0px;
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.abssize{
  width: 85px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-family: var(--test);
  font-size: 12px;
  color: #575757;
  border: 2px solid #ccc;
  cursor: pointer;
}


.absbtncont{
  width: 100%;
  height: 50px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;

}

.absaddtocart,.abswishlist{
  width: 45%;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fafafa;
  font-family: var(--test);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  z-index: 999 !important;

}


.abswishlist{
  background-color: #fff;
  color: #575757;
  border: 1px solid #000;
}


.mobproductcont{
  width: calc(100% - 10px);
  padding: 10px 5px;
  height: fit-content;
  display: none;
}

.mobimgslider{
  width: 100%;
  height: fit-content;
  display: flex;
  overflow: scroll;
  gap: 10px;
}

.mobimgslider::-webkit-scrollbar{
  width: 0px;
  background: transparent;
  display: none;
}

.mobimgg{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 300px;
  width: 300px;
  aspect-ratio: 3/4;
  background-color: #000;
}
.load{
  display: none;
  animation: load 1s infinite;
}

.sizechartabs{
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  display: none;
  z-index: 999;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.tabelcont{
  width: 400px;
  background-color: #fff;

}

.tabelcont table{
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.tabelcont table td{
  text-align: center;
  border: 1px solid #000;
  height: 40px;
  font-family: var(--test);
  font-size: 12px;
  font-weight: 500;
}

.tabelcont table th{
  text-align: center;
  border: 1px solid #000;
  height: 40px;
  font-family: var(--test);
  font-size: 16px;
  font-weight: 600;
}



@keyframes load {
  0%{
      transform: rotate(0deg);
  }
  50%{
      transform: rotate(180deg);
  }
  100%{
      transform: rotate(360deg);
  }
}

@media only screen and (max-width:990px) {
  .mainproductcont{
      display: none;
  }

  nav{
      position: relative;
  }

  .navinfo{
      position: relative;
      top: 0px;
  }
  .mobproductcont{
      display: block;
  }

  .abssizecont{
      margin-top: 0px;
      height: fit-content;
  }

  .absbtncont{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100px;
      align-items: center;
  }

  .absaddtocart,.abswishlist{
      width: 100%;
      border-radius: 5px;
  }
}

/* product end */

@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');

@layer base{

  @font-face {
    font-family: 'MainFont';
    src: url('/src/Glaera\ Elegance\ Regular.ttf') format('.ttf');
  }
}


@tailwind base;
@tailwind components;
@tailwind utilities;



